@import "../../constants";

.searchBox{
  position: relative;
  height: 44px;
  border-radius: 100px;
  overflow: hidden;
  background-color: $colorLightestGray;
  form{
    display: flex;
    height: 100%;
    width: 100%;

    .searchInput{
      height: 100%;
      width: 100%;
      padding-left: 20px;
      padding-right: 60px;
      font-size: 100%;
    }
    .searchSubmit{
      position: absolute;
      border-radius: 0;
      right: 0;
      height: 100%;
      padding: $defaultPadding $defaultPadding+5px $defaultPadding $defaultPadding;
      background-color: $colorBlue;
    }
  }
}
