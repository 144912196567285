$defaultPadding: 10px;

$defaultShadow: drop-shadow(0px 1px 0.5px rgba(#000, .4));
$defaultTransition: all .3s ease;
$maxWindowWidth: 900px;

$iconDiameter: 28px;

$colorBlue: #327ef9;
$colorBlack: #000;
$colorYellow: #ffc600;
$colorRed: #f84a21;
$colorPink: #ff8aea;
$colorGreen: #14b316;
$colorPurple: #842eff;
$colorLightestGray: #eee;
$colorTextGray: #BBB;
$colorWater: #ebf5ff;

@mixin wideScreen{
  @media screen and (min-width: 700px){
    @content;
  }
}

@mixin center{
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

