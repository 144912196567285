@import "../../../constants";

#jobPage{
  .pTitle{
    font-size: 120%;
    font-weight: bold;
    margin-top: $defaultPadding*2;
  }
  .multiLineP{
    font-size: 90%;
  }
  .contactForm{
    margin-top: $defaultPadding*4;
  }
  .jobId{
    text-align: center;
    margin-top: $defaultPadding/2;
    color: $colorTextGray;
    font-size: 80%;

  }
}
