@import "../../../constants";

#privacyPolicyPage{
  .article{
    h1{
      margin-top: $defaultPadding*5;
    }
    & > div{
      font-size: 90%;
      h2{
        margin-top: $defaultPadding*5;
      }
      p{
        margin-bottom: $defaultPadding*2;
      }
    }
  }
}
