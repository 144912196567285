@import "../../../constants";

#homePage{

  .searchContainer{
    width: 100%;
    margin: $defaultPadding;
    max-width: 460px;
    @include center;
    & > *:not(.searchBox){
      //margin-left: 16px;
    }
    .logo{
      //margin-left: 14px;
      height: 60px;
    }
    .searchBox{
      height: 52px;
      margin: $defaultPadding 0;
    }
    .exampleSearchTerms{
      font-size: 80%;
      .exampleSearchTerm{
        margin-right: $defaultPadding/2;
        border-bottom: 1px solid $colorBlue;
      }
    }
  }
}
