@import "../../constants";

.contactForm{
  width: 100%;
  padding: $defaultPadding*2 $defaultPadding;
  @include wideScreen{
    padding: $defaultPadding*4;
  }
  border: 1px solid $colorLightestGray;

  form{
    margin: 0 auto;
    max-width: 600px;

    //border-radius: 20px;
    //filter: drop-shadow(0px 0px 6px rgba(#000, .2));
    background-color: #fff;

    .title{
      font-size: 120%;
      font-weight: bold;
    }
    .linkToPrivacyPolicy{
      display: inline-block;
      font-size: 80%;
      margin-bottom: $defaultPadding*2;
    }

    .section{
      position: relative;

      &:not(:last-of-type){
        margin-bottom: $defaultPadding*2;
      }

      .sectionContents{
        position: relative;

        input, select, textarea{
          background-color: transparent;
          padding: $defaultPadding;
          font-size: 90%;
          width: 100%;
          border: 2px solid $colorLightestGray;
          border-radius: 0;
          min-height: 40px;
        }
        input[type="date"]{
          max-width: 340px;
        }
        textarea{
          border: 2px solid $colorLightestGray;
          min-height: 100px;
        }

        .sectionLabel{
          display: flex;
          align-content: space-between;
          position: relative;
          font-size: 80%;
          margin-bottom: 2px;
          .name{

          }
          .errorMessage{
            color: $colorRed;
            margin-left: $defaultPadding;
          }
        }
        .submitBtn{
          padding: $defaultPadding;
          width: 100%;
          max-width: 350px;
          border: 1px solid $colorLightestGray;
          border-radius: 100px;
          color: $colorBlue;
          @include center;
          margin: 0 auto;
          margin-top: $defaultPadding*2;
          background-color: transparent;
        }
      }

      &.hasError{
        input, select, textarea{
          border-color: $colorRed;
        }
      }
    }
  }
}
