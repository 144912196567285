@import "../../constants";

#footer{
  font-size: 80%;
  padding: $defaultPadding*2 $defaultPadding*2;
  @include wideScreen{
    padding: $defaultPadding*2 $defaultPadding*3;
  }

  @include center;
  .inner{
    width: 100%;
    max-width: $maxWindowWidth;
    text-align: center;
    & > *{
      display: inline-block;
    }
    & > *:not(:last-child){
      margin-right: $defaultPadding*2;
    }
  }
}
