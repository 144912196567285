@import "../../constants";

#header{
  @include center;
  position: sticky;
  z-index: 2;
  top: 0;
  padding: $defaultPadding $defaultPadding*2;
  @include wideScreen{
    padding: $defaultPadding*2 $defaultPadding*3;
  }

  background-color: #fff;
  border-bottom: 1px solid $colorLightestGray;
  .inner{
    display: flex;
    align-items: center;
    width: 100%;
    max-width: $maxWindowWidth;
    & > *:not(:last-child){
      margin-right: $defaultPadding;
    }
    .logo{
      @include center;
      height: 14px;
      @include wideScreen{
        height: 26px;
      }
    }
    .searchBox{
      flex-grow: 1;
      max-width: 360px;
    }

    .contactPageLink{
      margin-left: auto;
      font-size: 90%;
    }

  }
}
