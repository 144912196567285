@import "../../../constants";

#listPage{

  .contents{
    .article{
      align-self: flex-start;
    }
  }

  .message{

  }
  .listItems{
    margin-top: $defaultPadding*2;
    .listItem{
      border-top: 1px solid $colorLightestGray;
      padding: $defaultPadding 0;
      & > div:not(:last-of-type){
        margin-bottom: $defaultPadding/2;
      }
      em{
        font-weight: bold;
        font-style: unset;
      }
      .jobSubtitle, .jobLocation{
        font-size: 90%;
      }
      .jobLocation{

      }
    }
  }
  .loadMoreBtn{
    padding: $defaultPadding;
    width: 100%;
    max-width: 400px;
    border: 1px solid $colorLightestGray;
    border-radius: 100px;
    color: $colorBlue;
    @include center;
    margin: 0 auto;
    margin-top: $defaultPadding*2;
  }
}
