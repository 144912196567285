@import "../../../constants";

$lineMargin: $defaultPadding/4 0;

#companyPage{
  .contents{
    align-items: flex-start !important;
  }
  .article > *:not(:first-child){
    margin: $defaultPadding*4 0;
  }
  .companyPageLinks{
    border-bottom: 1px solid $colorLightestGray;
    padding: $defaultPadding*2 0;
    & > a{
      &:first-of-type{
        border-right: 1px solid #000;
        padding-right: $defaultPadding;
        @include wideScreen{
          padding-right: $defaultPadding*1.5;
        }
      }
      &.selected{
        color: #000;
      }
      &:not(:last-of-type){
        margin-right: $defaultPadding;
        @include wideScreen{
          margin-right: $defaultPadding*1.5;
        }
      }
    }
  }

  h1{

  }
  .logoSection{
    .logo{
      height: 32px;
    }
    .companyName{
      font-weight: bold;
    }
  }

  .label{
    font-weight: bold;
  }
  .grid{
    display: grid;
    grid-template-columns: 100px 1fr;
    & > div{
      margin: $defaultPadding/4 0;
    }
  }
  li{
    margin: $defaultPadding 0;
  }
  .mission{
    font-weight: bold;
    font-size: 1.6em;
    color: #999;
  }
  .shopLinks{
    display: flex;
    flex-wrap: wrap;
    .shopLink:not(:last-of-type){
      margin-right: $defaultPadding;
    }
    .shopLink{
      display: inline-block;
      background-color: #fff;
      filter: drop-shadow(0px 3px 5px rgba(#000, .2));
      border-radius: 6px;
      transition: filter 0.3s;
      margin-bottom: $defaultPadding;
      &:hover{
        filter: drop-shadow(0px 3px 5px rgba(#000, .4));
      }
      .imgContainer{
        width: 220px;
        height: 140px;
        display: flex;
        @include center;
        img{
          width: 160px;
        }
      }
    }
  }
}
