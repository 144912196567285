@import "constants";

*{
  box-sizing: border-box;
  font-family: 'Noto Sans CJK JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオUI', 'Meiryo', 'MS Pゴシック', 'MS P Gothic', sans-serif;
}

//scroll bar
::-webkit-scrollbar {
  width: $defaultPadding/2;
}
::-webkit-scrollbar-track {
  background: $colorLightestGray;
  border-width: 0;
}
::-webkit-scrollbar-thumb {
  background-color: #777;
}

html{
  overflow-y: overlay;
  height: 100%;
}
body{
  margin:0;
  padding:0;
  width: 100%;
  height: 100%;
}
#root{
  height: 100%;
}
circle{
  fill: red;
}
a{
  text-decoration: none;
  color: $colorBlue;
  &:visited{
    color: $colorBlue;
  }
}
input, select, textarea{
  outline: 0;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
}
canvas{
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: bottom;
}
button{
  appearance: none;
  font-size: 100%;
  border: none;
  background-color: transparent;
  padding: 0;

  &:hover{
    cursor: pointer;
  }
  &:focus { outline: none; }
  &:disabled{
    cursor: unset;
  }
}
select{
  display: inline-block;
  &:hover{
    cursor: pointer;
  }
  &:focus { outline: none; }
}
hr{
  border: none;
  border-top: 1px solid $colorLightestGray;
}
h1{
  margin: 0;
  font-size: 180%;
  font-weight: bold;
}
h2{
  margin: 0;
  font-size: 130%;
  font-weight: bold;
}
