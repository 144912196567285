@import "constants";

#app{
  position: relative;
  height: 100%;

  .page{
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 100%;

    .contents{
      flex-grow: 1;
      @include center;
      padding: $defaultPadding*2;
      @include wideScreen{
        padding: $defaultPadding*3;
      }

      .article{
        width: 100%;
        max-width: $maxWindowWidth;
      }
    }
  }
}
